import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggingService } from '@tdoe/design-system';
import { ICTEConcentratorStudentFollowUpViewModel } from 'src/app/interfaces/ICTEConcentratorStudentFollowUpViewModel';
import { POSSecLookup } from 'src/app/interfaces/PosSecLookup';
import { POSSecLookupService } from 'src/app/services/pos-sec-lookup/possec-lookup.service';

@Component({
  selector: 'app-student-follow-up-edit-form',
  templateUrl: './student-follow-up-edit-form.component.html',
  styleUrls: ['./student-follow-up-edit-form.component.scss']
})
export class StudentFollowUpEditFormComponent implements OnChanges, OnInit {
  @Input() concentrator?: ICTEConcentratorStudentFollowUpViewModel;
  @Output() concentratorUpdated = new EventEmitter<ICTEConcentratorStudentFollowUpViewModel>();

  editForm!: FormGroup;
  posSecLookups!: POSSecLookup[];

  constructor(private fb: FormBuilder,
              private posSecLookupService: POSSecLookupService,
              private logger: LoggingService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.logger.debug('student-follow-up-edit-form.ngOnChanges', {
        title: 'ENTER',
        data: {
          changes
        }
    });
    if (changes.concentrator.currentValue) {
      this.populateForm(changes.concentrator.currentValue);
    }
  }

  async ngOnInit(): Promise<void> {
    this.posSecLookups = await this.posSecLookupService.listLookups();
  }

  async onSubmit(): Promise<void> {
    const concentrator: ICTEConcentratorStudentFollowUpViewModel = this.concentrator ?? {} as ICTEConcentratorStudentFollowUpViewModel;
    Object.assign(concentrator, this.editForm.value);
    this.concentratorUpdated.emit(concentrator);
  }

  populateForm(concentrator?: ICTEConcentratorStudentFollowUpViewModel): void {
    this.editForm = this.fb.group({
      // highSchoolGraduated: [concentrator?.highSchoolGraduated ?? undefined, [Validators.required]],
      postSecAdvancedTraining: [concentrator?.postSecAdvancedTraining ?? null, [Validators.required]],
      nameOfEnrolledPostSecAdvancedTraining: [concentrator?.nameOfEnrolledPostSecAdvancedTraining ?? null],
      postProgramEnrolled: [concentrator?.postProgramEnrolled ?? null, [Validators.required]],
      majorOrCourseFocus: [concentrator?.majorOrCourseFocus ?? null],
      tookRemedialCourse: [concentrator?.tookRemedialCourse ?? null],
      usedEPSOCredit: [concentrator?.usedEPSOCredit ?? null],
      military: [concentrator?.military ?? null, [Validators.required]],
      branchOfService: [concentrator?.branchOfService ?? null],
      employed: [concentrator?.employed ?? null, [Validators.required]],
      employer: [concentrator?.employer ?? null],
      jobTitle: [concentrator?.jobTitle ?? null],
      // annualIncome: [concentrator?.annualIncome ?? null],
      relatedToHSCTEProgram: [concentrator?.relatedToHSCTEProgram ?? null],
      nationalCommunityServiceOrPeaceCorps: [concentrator?.nationalCommunityServiceOrPeaceCorps ?? null, [Validators.required]],
      exclude: [concentrator?.exclude ?? null],
      comments: [concentrator?.comments ?? null]
    });
  }

}
