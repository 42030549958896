import { Component, OnInit } from '@angular/core';
import { LoggingService, AuthService } from '@tdoe/design-system';
import { AppService } from './services/app-service/app.service';
import { FlyoutCommand, NavItem } from '@tdoe/design-system/lib/_models';
import { ClientConfiguration } from './interfaces/clientConfiguration';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = '';
  flyoutCommands: FlyoutCommand[] = [];
  navItems: NavItem[] = [];

  constructor(private appService: AppService,
              private logger: LoggingService,
              private authService: AuthService) {


  }

  ngOnInit(): void {
    this.logger.debug('app.component', {
        title: 'NGONINIT'
    });
    this.appService.initialize();
    this.authService.entraIdAccount$.subscribe(_ => {
      if (_) {
        this.appService.clientConfiguration$.subscribe(config => this.onClientConfig(config));
        this.navItems = [
          {
            text: 'Follow-Up',
            id: 'a',
            route: ['/list']
          }
        ];
      } else {
        this.navItems = [];
      }
    });
  }

  onClientConfig(config?: ClientConfiguration): void {
    if (config) {
      this.title = config.appName;
      this.flyoutCommands = [
        { text: `Client: v${config.clientVersion}` },
        { text: `API: v${config.apiVersion}` }
      ];
    }
  }

}
