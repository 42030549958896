import { InteractionType } from "@azure/msal-browser";
import { LogLevel, TdoeAuthConfig } from "@tdoe/design-system";

const tdoeAuthConfig: TdoeAuthConfig = {
  authRequestScopes: [
    'api://b3be471a-14af-4995-9b95-baf566aba415/access_as_user'
  ],
  clientId: '4f2b4c05-7c40-49ac-b500-fdccb2c8b56a',
  instance: 'https://login.microsoftonline.com',
  loginFailedRoute: '/login-failed',
  protectedResources: [
    {
      key: 'https://ccte-api.dev.tnedu.gov/api',
      value: [
        'api://b3be471a-14af-4995-9b95-baf566aba415/access_as_user'
      ]
    },
    {
      key: 'https://graph.microsoft.com/v1.0/me',
      value: [
        'user.read'
      ]
    }
  ],
  redirectUri: 'https://ccte.dev.tnedu.gov',
  tenantId: '472445bd-2424-4e8f-b850-df7488e18b4a',
  logLevel: LogLevel.debug,
  takeOverConsoleLog: false,
  interactionType: InteractionType.Redirect,
  projectKey: 'BF3DD0C7',
  authEnabled: true
};


export const environment = {
  production: true,
  authConfig: tdoeAuthConfig,
  apiUrl: 'https://ccte-api.dev.tnedu.gov',
};
