<form [formGroup]="editForm" (ngSubmit)="onSubmit()" *ngIf="editForm">
    <div>
        <h2 [innerText]="concentrator?.studentName + ' - ' + concentrator?.studentKey"></h2>
    </div>
    <mat-divider></mat-divider>
    <div>
        <mat-form-field>
            <mat-label>Post Sec. / Adv. Training</mat-label>
            <mat-select formControlName="postSecAdvancedTraining">
                <mat-option [value]="null">Please select</mat-option>
                <mat-option [value]="'None'">None</mat-option>
                <mat-option [value]="'Community College'">Community College</mat-option>
                <mat-option [value]="'TN College of Applied Technology'">TN College of Applied Technology</mat-option>
                <mat-option [value]="'4 Year College/University'">4 Year College/University</mat-option>
                <mat-option [value]="'Apprenticeship'">Apprenticeship</mat-option>
                <mat-option [value]="'Short Term Training School/Program'">Short Term Training School/Program</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="posSecLookups">
        <mat-form-field>
            <mat-label>Name of Post Sec. / Adv. Training</mat-label>
            <mat-select formControlName="nameOfEnrolledPostSecAdvancedTraining">
                <mat-option [value]="null">Please select</mat-option>
                <mat-option *ngFor="let lookup of posSecLookups" [value]="lookup.schoolName">{{ lookup.schoolName }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <mat-label>Post Program Enrolled</mat-label>
            <mat-select formControlName="postProgramEnrolled">
                <mat-option [value]="null">Please select</mat-option>
                <mat-option [value]="'None'">None</mat-option>
                <mat-option value="Certifications">Certifications</mat-option>
                <mat-option value="2-Years">2-Years</mat-option>
                <mat-option value="4-Years">4-Years</mat-option>
                <mat-option value="Other">Other</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="editForm.get('postProgramEnrolled')?.value">
        <mat-form-field appearance="fill">
            <mat-label>Major or Course Focus</mat-label>
            <input type="text" formControlName="majorOrCourseFocus" matInput>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <mat-label>Took Remedial Course</mat-label>
            <mat-select formControlName="tookRemedialCourse">
                <mat-option [value]="undefined">Please select</mat-option>
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <mat-label>Used EPSO Credit</mat-label>
            <mat-select formControlName="usedEPSOCredit">
                <mat-option [value]="undefined">Please select</mat-option>
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <mat-label>Military</mat-label>
            <mat-select formControlName="military">
                <mat-option [value]="undefined">Please select</mat-option>
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="editForm.get('military')?.value === true">
        <mat-form-field appearance="fill">
            <mat-label>Branch of Service</mat-label>
            <input type="text" formControlName="branchOfService" matInput>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <mat-label>Employed</mat-label>
            <mat-select formControlName="employed">
                <mat-option [value]="undefined">Please select</mat-option>
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="editForm.get('employed')?.value === true">
        <mat-form-field appearance="fill">
            <mat-label>Employer</mat-label>
            <input type="text" formControlName="employer" matInput>
        </mat-form-field>
    </div>
    <div *ngIf="editForm.get('employed')?.value === true">
        <mat-form-field appearance="fill">
            <mat-label>Job Title</mat-label>
            <input type="text" formControlName="jobTitle" matInput>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="fill">
            <mat-label>Related to HS CTE Program</mat-label>
            <input type="text" formControlName="relatedToHSCTEProgram" matInput>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <mat-label>Nat'l Comm. Svc. or Peace Corps</mat-label>
            <mat-select formControlName="nationalCommunityServiceOrPeaceCorps">
                <mat-option [value]="undefined">Please select</mat-option>
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="fill">
            <mat-label>Exclude</mat-label>
            <textarea formControlName="exclude" matInput></textarea>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field appearance="fill">
            <mat-label>Comments</mat-label>
            <textarea formControlName="comments" matInput></textarea>
        </mat-form-field>
    </div>
    <button mat-flat-button color="primary" type="submit" [disabled]="editForm.invalid">Submit</button>
</form>