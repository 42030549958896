import { Component } from '@angular/core';
import { AuthService, LoggingService } from '@tdoe/design-system';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [
    './home.component.scss'
  ]
})
export class HomeComponent {
  authenticated = false;

  constructor(private logger: LoggingService,
              authService: AuthService) {

    authService.entraIdAccount$.subscribe(_ => {
      this.authenticated = _ !== null;
    });

  }

}
